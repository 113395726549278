import { StandardResponse, VerificationInfoResponse } from '@dave-inc/wire-typings';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import { get, SubmitHandler, useForm, Controller } from 'react-hook-form';
import { z } from 'zod';

import { EVENTS, trackEvent } from '../../../lib/analytics';
import client, { API_ENDPOINTS } from '../../../lib/api-client';
import { Config } from '../../../lib/config';
import { CUSTOM_ERROR_CODES, errorToString, getErrorCode } from '../../../lib/error';
import { getDigits, replaceDaveTag } from '../../../lib/format';
import { useIsMobile } from '../../../lib/hooks';
import {
  hasALowerCaseLetter,
  hasAnUpperCaseLetter,
  hasASpecialCharacter,
  hasMinLength,
  hasNumber,
  nameRegexValidation,
  phoneNumberRegexValidation,
} from '../../../lib/validation';

import { Button } from '../../button';
import { TextInput } from '../../text-input';
import { BodyMd, BodySm, H5, H6 } from '../../typography';
import { disclaimersLinks } from '../components/disclaimers';
import PasswordRequirement from '../components/password-requirements';
import { ERROR_MESSAGES } from '../constants';
import { RegistrationValues, Step } from '../registration';

export type FormProps = {
  formTitle?: string;
  formValues: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber: string;
    password: string;
  };
  setFormValues: ({
    firstName,
    lastName,
    email,
    phoneNumber,
    password,
  }: RegistrationValues) => void;
  setIsNewUser: (isNewUser: boolean) => void;
  setProgressAmount: (amount: number) => void;
  setStep: (step: Step) => void;
  referralCampaignValues: {
    campaign: string;
    daveTag: string;
  };
  referralTerms?: string | undefined | null;
};

const validationSchema = z.object({
  email: z
    .string({ required_error: ERROR_MESSAGES.email.default })
    .min(1, { message: ERROR_MESSAGES.email.default })
    .email({
      message: ERROR_MESSAGES.email.default,
    }),
  firstName: z
    .string({ required_error: ERROR_MESSAGES.firstName.default })
    .min(1, { message: ERROR_MESSAGES.firstName.default })
    .regex(nameRegexValidation, {
      message: ERROR_MESSAGES.firstName.format,
    }),
  lastName: z
    .string({ required_error: ERROR_MESSAGES.lastName.default })
    .min(1, { message: ERROR_MESSAGES.lastName.default })
    .regex(nameRegexValidation, {
      message: ERROR_MESSAGES.lastName.format,
    }),
  password: z
    .string({ required_error: ERROR_MESSAGES.password.default })
    .min(8, { message: ERROR_MESSAGES.password.default })
    .max(72, { message: ERROR_MESSAGES.password.maxLength })
    .refine(val => hasALowerCaseLetter(val), {
      path: ['lowercase'],
      message: ERROR_MESSAGES.password.lowercase,
    })
    .refine(val => hasAnUpperCaseLetter(val), {
      path: ['uppercase'],
      message: ERROR_MESSAGES.password.uppercase,
    })
    .refine(val => hasASpecialCharacter(val), {
      path: ['special'],
      message: ERROR_MESSAGES.password.special,
    })
    .refine(val => hasNumber(val), {
      path: ['number'],
      message: ERROR_MESSAGES.password.number,
    }),
  phoneNumber: z
    .string({ required_error: ERROR_MESSAGES.phoneNumber.default })
    .min(10, { message: ERROR_MESSAGES.phoneNumber.default })
    .regex(phoneNumberRegexValidation, {
      message: ERROR_MESSAGES.phoneNumber.default,
    }),
});

type ValidationSchema = z.infer<typeof validationSchema>;

const Form: FC<FormProps> = ({
  formTitle = 'Join Dave today',
  formValues,
  setFormValues,
  setIsNewUser,
  setProgressAmount,
  setStep,
  referralCampaignValues,
  referralTerms,
}) => {
  const [hasAllValues, setHasAllValues] = useState(false);
  const [showPasswordReqs, setShowPasswordReqs] = useState(false);
  const [isPasswordCharValid, setIsPasswordCharValid] = useState(false);
  const [isPasswordLengthValid, setIsPasswordLengthValid] = useState(false);
  const {
    control,
    getFieldState,
    getValues,
    handleSubmit,
    setError,
    setValue,
    watch,
    formState: { errors, isDirty, isSubmitting, isValid, dirtyFields, touchedFields },
  } = useForm<ValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(validationSchema),
  });
  const isMobile = useIsMobile();

  const onClickEvent = (href: string) => {
    trackEvent(EVENTS.LINK_CLICKED, { all: { href } });
  };

  const handleSubmitError = (field: keyof ValidationSchema, error: Error | unknown) => {
    const errorString = errorToString(error, true);
    const errorCode = getErrorCode(error);

    setError(field, {
      type: errorCode,
      message: errorString,
    });

    trackEvent(EVENTS.JOIN_DAVE_FAILED, { all: { errorString } });
  };

  const onFieldBlur = (fieldName: keyof ValidationSchema) => {
    const { error } = getFieldState(fieldName);
    if (error?.message) {
      trackEvent(EVENTS.FORM_FIELD_VALIDATION_FAILED, {
        all: { name: fieldName, error: error?.message },
      });
    }
  };

  const onPasswordChange = (value: string) => {
    const password = value;
    const isCharValid =
      hasAnUpperCaseLetter(password) &&
      hasALowerCaseLetter(password) &&
      hasNumber(password) &&
      hasASpecialCharacter(password);
    setIsPasswordLengthValid(hasMinLength(password));
    setIsPasswordCharValid(isCharValid);
    setValue('password', password, { shouldValidate: password?.length >= 8 });
  };

  useEffect(() => {
    const subscription = watch(value => {
      const valuesArray = Object.values(value);
      const filledValues = valuesArray.filter((val: string) => val?.length > 0);
      const numFilledValues = filledValues.length;
      if (numFilledValues > 0) {
        setProgressAmount(numFilledValues * 15);
      }
    });
    return () => subscription.unsubscribe();
  }, [watch, setProgressAmount]);

  useEffect(() => {
    const subscription = watch(value => {
      const valuesArray = Object.values(value);
      const filledValues = valuesArray.filter((val: string) => val?.length > 0);
      const numFilledValues = filledValues.length;
      if (numFilledValues < 5) {
        setHasAllValues(false);
        return;
      }
      setHasAllValues(true);
    });
    return () => subscription.unsubscribe();
  }, [watch, errors]);

  const verifyEmail = async () => {
    const email = getValues('email');
    const socureSessionToken = await window.SigmaDeviceManager?.getSessionToken();

    await client.get(API_ENDPOINTS.checkDuplicateEmail, {
      params: { email },
      headers: {
        'x-device-session-token': socureSessionToken,
      },
    });
  };

  const verifyPhoneNumber = async () => {
    const phoneNumber = getValues('phoneNumber');
    const phoneDigits = getDigits(phoneNumber);
    const socureSessionToken = await window.SigmaDeviceManager?.getSessionToken();

    try {
      const {
        data: { isNewUser },
      } = await client.post<VerificationInfoResponse>(
        API_ENDPOINTS.verifyPhone,
        {
          phoneNumber: phoneDigits,
          isSignUp: true,
        },
        {
          headers: {
            'x-device-session-token': socureSessionToken,
          },
        },
      );

      if (isNewUser) {
        await client.post<StandardResponse>(
          API_ENDPOINTS.sendVerificationText,
          {
            phoneNumber: phoneDigits,
          },
          {
            headers: {
              'x-device-session-token': socureSessionToken,
            },
          },
        );
        trackEvent(EVENTS.JOIN_DAVE_SUCCESS, { all: { isNewUser: Boolean(isNewUser) } });
      }

      if (isNewUser) {
        setStep('verify');
      } else {
        setIsNewUser(false);
        setStep('download');
      }
    } catch (error) {
      let errorString = errorToString(error, true);
      const errorCode = getErrorCode(error);

      if (errorCode === CUSTOM_ERROR_CODES.DELETED_ACCOUNT_TOO_SOON_ERROR) {
        errorString = `Sorry, but you've got to wait ${get(
          error,
          'response.data.data.daysRemaining',
        )} days before I can let you back in. It costs us money when you delete/rejoin.`;
      } else if (errorCode === CUSTOM_ERROR_CODES.MESSAGES_UNSUBSCRIBED_ERROR) {
        errorString = `Text notifications are disabled. Text "Start" to ${Config.MFA_SHORT_CODE} to get the verification code.`;
      }

      throw errorString;
    }
  };

  const submitForm: SubmitHandler<ValidationSchema> = async () => {
    const { firstName, lastName, email, phoneNumber, password } = getValues();
    trackEvent(EVENTS.JOIN_DAVE_ATTEMPT, { all: { email, phoneNumber } });

    try {
      await verifyEmail();
    } catch (err) {
      handleSubmitError('email', err);
      return;
    }

    try {
      await verifyPhoneNumber();
    } catch (err) {
      handleSubmitError('phoneNumber', err);
      return;
    }

    setFormValues({
      firstName,
      lastName,
      email,
      phoneNumber,
      password,
    });
    setProgressAmount(90);
  };

  const Header = isMobile ? H6 : H5;

  const title = () =>
    replaceDaveTag({
      s: formTitle,
      daveTag: referralCampaignValues?.daveTag,
      defaultString: 'Join Dave today',
    });

  return (
    <div>
      <Header as="h1" className="mb-3">
        {title()}
      </Header>
      <BodyMd className="mb-6">First, a little information about you.</BodyMd>
      <form
        onSubmit={handleSubmit(submitForm)}
        className="grid gap-6 text-left md:grid-cols-2"
        data-testid="register-form"
      >
        <Controller
          control={control}
          name="firstName"
          render={({ field: { onBlur, onChange, value } }) => {
            return (
              <TextInput
                aria-describedby="firstName-message"
                aria-invalid={Boolean(errors?.firstName)}
                aria-required="true"
                autoComplete="given-name"
                data-testid="firstNameInput"
                value={value || formValues.firstName}
                error={Boolean(errors.firstName?.message)}
                helperText={errors.firstName?.message}
                id="firstName"
                label="First name"
                placeholder="Jane"
                type="text"
                onBlur={() => {
                  onBlur();
                  onFieldBlur('firstName');
                }}
                onChange={onChange}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="lastName"
          render={({ field: { onBlur, onChange, value } }) => (
            <TextInput
              aria-describedby="lastName-message"
              aria-invalid={Boolean(errors?.lastName)}
              aria-required="true"
              autoComplete="family-name"
              data-testid="lastNameInput"
              value={value || formValues.lastName}
              error={Boolean(errors.lastName?.message)}
              helperText={errors.lastName?.message}
              id="lastName"
              label="Last name"
              placeholder="Doe"
              required
              type="text"
              onBlur={() => {
                onBlur();
                onFieldBlur('lastName');
              }}
              onChange={onChange}
            />
          )}
        />
        <div className="md:col-span-2">
          <Controller
            control={control}
            name="email"
            render={({ field: { onBlur, onChange, value } }) => (
              <TextInput
                aria-describedby="email-message"
                aria-invalid={Boolean(errors?.email)}
                aria-required="true"
                autoComplete="email"
                data-testid="emailInput"
                value={value || formValues.email}
                error={Boolean(errors.email?.message)}
                helperText={errors.email?.message}
                id="email"
                label="Email address"
                placeholder="jane@example.com"
                type="email"
                onBlur={() => {
                  onBlur();
                  onFieldBlur('email');
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="md:col-span-2">
          <Controller
            control={control}
            name="phoneNumber"
            render={({ field: { value, onBlur, onChange } }) => (
              <TextInput
                aria-describedby="phoneNumber-message"
                aria-invalid={Boolean(errors?.phoneNumber)}
                aria-required="true"
                autoComplete="tel-national"
                data-testid="phoneNumberInput"
                value={value || formValues.phoneNumber}
                error={Boolean(errors.phoneNumber?.message)}
                helperText={errors.phoneNumber?.message}
                id="phoneNumber"
                label="Mobile phone"
                placeholder="Mobile phone"
                type="tel"
                onBlur={() => {
                  onBlur();
                  onFieldBlur('phoneNumber');
                }}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className="md:col-span-2">
          <Controller
            control={control}
            name="password"
            render={({ field: { value, onBlur, onChange } }) => (
              <TextInput
                aria-describedby="password-message"
                aria-invalid={Boolean(errors?.password)}
                aria-required="true"
                autoComplete="password"
                data-testid="passwordInput"
                value={value}
                error={Boolean(errors.password)}
                helperText={
                  errors.password?.message ||
                  errors.password?.uppercase?.message ||
                  errors.password?.lowercase?.message ||
                  errors.password?.number?.message ||
                  errors.password?.special?.message
                }
                id="password"
                label="Set password"
                type="password"
                onBlur={() => {
                  onBlur();
                  onFieldBlur('password');
                }}
                onChange={val => {
                  onChange();
                  onPasswordChange(val);
                }}
                onFocus={() => {
                  setShowPasswordReqs(true);
                }}
              />
            )}
          />
          {showPasswordReqs && (
            <>
              <BodySm className="mt-2" color="text-dave-black">
                Your password
              </BodySm>
              <PasswordRequirement
                isTouched={touchedFields?.password || dirtyFields?.password}
                isValid={isPasswordLengthValid}
                message={ERROR_MESSAGES.password.lengthRequirement}
              />
              <PasswordRequirement
                isTouched={touchedFields?.password || dirtyFields?.password}
                isValid={isPasswordCharValid}
                message={ERROR_MESSAGES.password.characterRequirement}
              />
            </>
          )}
        </div>
        <div className="md:col-span-2">
          <div
            className="mt-4  text-center text-xs leading-normal text-dave-forest"
            data-testid={`${referralTerms}`}
          >
            {referralTerms && (
              <p className="mx-auto mb-4 w-80">
                <Link
                  to={`/${referralTerms}`}
                  className="font-bold text-dave-green underline"
                  onClick={() => onClickEvent(`/${referralTerms}`)}
                  data-testid="disclaimer-link-referral"
                >
                  See referral terms
                </Link>
              </p>
            )}
            <p className="mx-auto w-80">
              I agree to Dave's{' '}
              {disclaimersLinks.map((linkItem, index) => {
                const { url, name } = linkItem;
                return (
                  <span key={name}>
                    <Link
                      to={url}
                      className="font-bold text-dave-green underline"
                      onClick={() => onClickEvent(url)}
                      data-testid={`disclaimer-link-${index}`}
                    >
                      {name}
                    </Link>
                    {`${
                      // eslint-disable-next-line no-nested-ternary
                      index + 1 === disclaimersLinks.length - 1
                        ? ', & '
                        : index + 1 < disclaimersLinks.length
                        ? ', '
                        : ''
                    }`}
                  </span>
                );
              })}
            </p>
          </div>
          <Button
            data-testid="submitButton"
            type="submit"
            className="mt-8 w-full"
            color="green"
            disabled={
              !isDirty ||
              isSubmitting ||
              !hasAllValues ||
              !isValid ||
              !!Object.entries(errors).length
            }
            size="standard"
            text="Agree and continue"
            variant="primary"
          />
        </div>
      </form>
    </div>
  );
};

export default Form;
