import React, { ComponentPropsWithoutRef, FC, ReactNode } from 'react';

import ErrorMessage from '../registration/components/error-message';

type TermsCheckBoxProps = {
  centered?: boolean;
  errorMessage?: string | undefined;
  termsCopy: string | ReactNode;
};

const TermsCheckbox: FC<TermsCheckBoxProps & ComponentPropsWithoutRef<'input'>> = ({
  centered = true,
  errorMessage,
  termsCopy,
  ...inputProps
}) => {
  return (
    <>
      <div className={`mt-4 flex ${centered ? 'items-center' : ''}`}>
        <input
          data-testid="termsCheckbox"
          type="checkbox"
          className="h-6 w-6 flex-none rounded text-lg text-dave-green focus:outline-none focus:ring-1 focus:ring-dave-green"
          {...inputProps}
        />
        <p className="ml-2 text-left text-sm leading-snug text-legacy-gray-800">{termsCopy}</p>
      </div>
      {errorMessage && <ErrorMessage message={errorMessage} inputName="firstName" />}
    </>
  );
};

export default TermsCheckbox;
