export const ERROR_MESSAGES = {
  firstName: {
    default: 'Please provide your first name.',
    format: "Alphabetical characters, hyphens (-), apostrophes ('), and spaces ( ) only.",
  },
  lastName: {
    default: 'Please provide your last name.',
    format: "Alphabetical characters, hyphens (-), apostrophes ('), and spaces ( ) only.",
  },
  email: {
    default: 'Please provide a valid email address.',
  },
  phoneNumber: {
    default: 'Please provide a valid US phone number.',
  },
  password: {
    default: 'Your password must be at least 8 characters.',
    maxLength: 'Your password must be a maximum of 72 characters.',
    lowercase: 'Your password must have at least 1 lowercase letter.',
    uppercase: 'Your password must have at least 1 uppercase letter',
    special: 'Your password must have at least 1 special character.',
    number: 'Your password must have at least 1 number',
    lengthRequirement: 'Must be between 8 - 72 characters',
    characterRequirement: 'Must contain 1 uppercase, 1 lowercase, 1 number and 1 special character',
  },
  verificationCode: {
    default: 'Please provide the verification code you received.',
  },
};
