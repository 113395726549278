import { PageProps } from 'gatsby';
import { FC, useEffect, useMemo, useState } from 'react';

import { EVENTS, trackEvent } from '../../lib/analytics';
import { QRCodeImageProps } from '../qr-code/qr-code';

import { TermsModalProps } from './components/terms-modal';
import { TermsLinkButtonProps, ValueItemProps, ValuesPanelProps } from './components/values-panel';
import RegisterLayout from './layouts/register-layout';
import DownloadApp from './steps/download-app';
import Form, { FormProps } from './steps/form';
import VerifyPhone from './steps/verify-phone';

export type RegistrationProps = {
  data: {
    formHeadline: FormProps['formTitle'];
    qrCode: QRCodeImageProps;
    referralTerms?: Queries.ContentfulMainTemplate;
    slug: string;
    termsLinkButton: TermsLinkButtonProps;
    termsModal?: TermsModalProps;
    valuesPanelItems: ValueItemProps[];
    valuesPanelTitle: ValuesPanelProps['panelTitle'];
    downloadHeadline?: string;
  };
  storedCampaign: string;
  storedDaveTag: string;
} & Pick<PageProps, 'location'>;

export type Step = 'form' | 'verify' | 'download';

export type RegistrationValues = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber: string;
  password: string;
};

const trackBrazeUser = async (email?: string, isPreWebRegistration?: boolean) => {
  try {
    const braze = await import('../../lib/braze');
    braze.initBraze();
    if (isPreWebRegistration) {
      braze.setUserProperty('PRE_WEB_REGISTRATION', 'IS_TRUE');
    }
    if (email) {
      braze.setEmail(email);
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Error loading Braze module:', error);
  }
};

const Registration: FC<RegistrationProps> = ({
  data,
  location,
  storedCampaign = '',
  storedDaveTag = '',
}) => {
  const params = useMemo(() => new URLSearchParams(location.search), [location.search]);

  const referralCampaignValues = useMemo(() => {
    return {
      campaign: params.get('c') || storedCampaign,
      daveTag: params.get('daveTag') || storedDaveTag,
    };
  }, [params, storedCampaign, storedDaveTag]);

  const autoFilledFormValues = useMemo(() => {
    return {
      email: params.get('email') || '',
      firstName: params.get('firstname') || '',
      lastName: params.get('lastname') || '',
      phoneNumber: params.get('phonenumber') || '',
    };
  }, [params]);

  const [step, setStep] = useState<Step>('form');
  const [isNewUser, setIsNewUser] = useState(true);
  const [progressAmount, setProgressAmount] = useState(10);
  const [userId, setUserId] = useState('');

  const [formValues, setFormValues] = useState<RegistrationValues>({
    ...autoFilledFormValues,
    password: '',
  });

  const registrationSteps = {
    form: (
      <Form
        formTitle={data.formHeadline}
        formValues={formValues}
        setFormValues={setFormValues}
        setIsNewUser={setIsNewUser}
        setStep={setStep}
        setProgressAmount={setProgressAmount}
        referralCampaignValues={referralCampaignValues}
        referralTerms={data?.referralTerms?.slug as Queries.ContentfulMainTemplate['slug']}
      />
    ),
    verify: (
      <VerifyPhone
        formValues={formValues}
        setStep={setStep}
        setProgressAmount={setProgressAmount}
        setUserIdState={setUserId}
      />
    ),
    download: (
      <DownloadApp
        qrCode={data?.qrCode}
        isNewUser={isNewUser}
        campaign={storedCampaign}
        daveTag={storedDaveTag}
        headlineTitle={data?.downloadHeadline}
        userId={userId}
      />
    ),
  };

  useEffect(() => {
    const { email } = formValues;
    if (email && email !== autoFilledFormValues?.email) {
      trackBrazeUser(email, false);
    }
  }, [autoFilledFormValues, formValues]);

  useEffect(() => {
    const { firstName, lastName, email, phoneNumber } = autoFilledFormValues;

    if (firstName || lastName || email || phoneNumber) {
      trackBrazeUser(email, true);

      trackEvent(EVENTS.FORM_FIELD_AUTOFILL, {
        all: {
          ...autoFilledFormValues,
        },
      });
    }
  }, [autoFilledFormValues]);

  useEffect(() => {
    const stepToEvent: Record<Step, string> = {
      form: referralCampaignValues?.campaign
        ? EVENTS.RECIPIENT_LANDING_PAGE_VIEWED
        : EVENTS.PHONE_NUMBER_REGISTER_LOADED,
      verify: EVENTS.PHONE_NUMBER_VERIFY_LOADED,
      download: isNewUser ? EVENTS.SUCCESSFUL_REGISTRATION_LOADED : EVENTS.WELCOME_BACK_LOADED,
    };

    trackEvent(stepToEvent[step], {
      all: {
        inviteId: referralCampaignValues?.daveTag ?? '',
        offer: referralCampaignValues?.campaign ?? '',
      },
    });
  }, [isNewUser, referralCampaignValues, step]);

  return (
    data && (
      <RegisterLayout data={data} progressAmount={progressAmount}>
        {registrationSteps[step]}
      </RegisterLayout>
    )
  );
};

export default Registration;
