import { IUserRegistrationResponse } from '@dave-inc/types-sombra';
import { StandardResponse } from '@dave-inc/wire-typings';
import { zodResolver } from '@hookform/resolvers/zod';
import { Link } from 'gatsby';
import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import MaskedInput from 'react-text-mask';
import { z } from 'zod';

import { EVENTS, setUserId, setUserProperties, trackEvent } from '../../../lib/analytics';
import client, { API_ENDPOINTS } from '../../../lib/api-client';
import { CUSTOM_ERROR_CODES, errorToString, getErrorCode } from '../../../lib/error';
import { getDigits } from '../../../lib/format';
import { isBrowser } from '../../../lib/helpers/server';
import { useIsMobile } from '../../../lib/hooks';
import { URLS } from '../../../lib/urls';
import { validationCodeInputMask } from '../../../lib/validation';

import { Button } from '../../button';
import { Icon } from '../../icon';
import CheckIcon from '../../icon/icons/check-icon.svg';
import TermsCheckbox from '../../terms-checkbox/terms-checkbox';
import { BodyMd, H5, H6 } from '../../typography';
import ErrorMessage from '../components/error-message';
import { ERROR_MESSAGES } from '../constants';
import { RegistrationValues, Step } from '../registration';

const validationSchema = z.object({
  code: z.string().min(6, { message: ERROR_MESSAGES.verificationCode.default }),
  isSMSChecked: z.optional(z.boolean()),
});

export type ValidationSchema = z.infer<typeof validationSchema>;

type VerifyPhoneProps = {
  formValues: RegistrationValues;
  setProgressAmount: (amount: number) => void;
  setStep: (step: Step) => void;
  setUserIdState: (userId: string) => void;
};

const VerifyPhone: FC<VerifyPhoneProps> = ({
  formValues,
  setProgressAmount,
  setStep,
  setUserIdState,
}) => {
  const [verifyCodeResent, setVerifyCodeResent] = useState(false);
  const {
    control,
    getValues,
    handleSubmit,
    setError,
    formState: { errors, isSubmitting, isValid },
  } = useForm<ValidationSchema>({
    mode: 'onTouched',
    resolver: zodResolver(validationSchema),
  });

  const { firstName, lastName, email, phoneNumber, password } = formValues;
  const isMobile = useIsMobile();
  const Header = isMobile ? H6 : H5;

  // Scroll to top of window for mobile users that scrolled down the page on the previous step
  useEffect(() => {
    if (isBrowser) {
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, []);

  const onCheckboxToggle = () => {
    const { isSMSChecked } = getValues();
    trackEvent(EVENTS.SMS_CHECKBOX_TOGGLED, {
      all: { notification_type: 'sms', value: isSMSChecked ? 'enabled' : 'disabled' },
    });
  };

  const handleSubmitError = (field: keyof ValidationSchema, error: Error | unknown) => {
    const errorString = errorToString(error, true);
    const errorCode = getErrorCode(error);

    setError(field, {
      type: errorCode,
      message: errorString,
    });
  };

  const onResend = async () => {
    const socureSessionToken = await window.SigmaDeviceManager?.getSessionToken();

    try {
      trackEvent(EVENTS.PHONE_NUMBER_RESEND_VERIFICATION_CODE);
      await client.post<StandardResponse>(
        API_ENDPOINTS.sendVerificationText,
        {
          phoneNumber: getDigits(phoneNumber),
        },
        {
          headers: {
            'x-device-session-token': socureSessionToken,
          },
        },
      );
      trackEvent(EVENTS.PHONE_NUMBER_RESEND_VERIFICATION_CODE_SUCCESS);
      setVerifyCodeResent(true);
    } catch (error) {
      trackEvent(EVENTS.PHONE_NUMBER_RESEND_VERIFICATION_CODE_FAILED);

      handleSubmitError('code', error);
    }
  };

  const submitVerifyCode: SubmitHandler<ValidationSchema> = async () => {
    trackEvent(EVENTS.LINK_BUTTON_CLICKED);
    const { code: verificationCode, isSMSChecked } = getValues();

    try {
      setVerifyCodeResent(false);

      trackEvent(EVENTS.PHONE_NUMBER_VERIFICATION_REQUESTED);
      const socureSessionToken = await window.SigmaDeviceManager?.getSessionToken();

      const { data: user } = await client.post<IUserRegistrationResponse>(
        API_ENDPOINTS.registerUser,
        {
          firstName: firstName.trim(),
          lastName: lastName.trim(),
          email,
          password,
          phoneNumber: phoneNumber.replace(/\D/g, ''),
          code: getDigits(verificationCode),
          settings: {
            sms_notifications_enabled: isSMSChecked,
            sms_subscription_group_enabled: isSMSChecked,
          },
        },
        {
          headers: {
            'x-device-session-token': socureSessionToken,
          },
        },
      );
      setUserId(user.id);
      setUserIdState(user.id.toString());
      setUserProperties({ web_registered: true, has_app_logged_in: false });
      trackEvent(EVENTS.PHONE_NUMBER_VERIFICATION_SUCCESS);
      trackEvent(EVENTS.EMAIL_AND_PASSWORD_CREATION_SUCCESS);
      setProgressAmount(100);
      setStep('download');
    } catch (error) {
      let errorString: string = errorToString(error, true);
      const errorCode: string = getErrorCode(error);

      if (errorCode === CUSTOM_ERROR_CODES.INVALID_VERIFICATION_CODE_ERROR) {
        errorString = 'That code is incorrect, try again.';
      }

      const reason = `${errorCode} ${errorString}`;
      trackEvent(EVENTS.PHONE_NUMBER_VERIFICATION_FAILED, { all: { reason } });
      trackEvent(EVENTS.EMAIL_AND_PASSWORD_CREATION_FAIL);

      handleSubmitError('code', error);
    }
  };

  /* eslint-disable react/jsx-props-no-spreading */
  return (
    <div>
      <Header as="h1" className="mb-3">
        Verify your phone
      </Header>
      <BodyMd className="mb-6">Enter the code we just texted you</BodyMd>
      <form
        onSubmit={handleSubmit(submitVerifyCode)}
        className="text-left"
        data-testid="verify-phone-form"
      >
        <label htmlFor="code" className="sr-only">
          Verification Code (required):
        </label>
        <Controller
          control={control}
          name="code"
          render={({ field }) => (
            <MaskedInput
              aria-describedby="code-message"
              aria-required="true"
              autoComplete="one-time-code"
              className="mt-6 h-14 w-full rounded-md border border-legacy-gray-500 p-4 focus:border-legacy-green focus:outline-none focus:ring-2 focus:ring-legacy-green"
              data-testid="verificationCodeInput"
              guide={false}
              id="code"
              inputMode="numeric"
              mask={validationCodeInputMask}
              onBlur={field.onBlur}
              onChange={field.onChange}
              placeholder="Enter code"
              required
            />
          )}
        />
        {errors.code && <ErrorMessage message={errors.code?.message} inputName="code" />}
        <div className="flex text-left">
          <p className="mr-1 text-sm">Not seeing a code?</p>
          <button
            className="h-auto p-0 text-sm text-legacy-blue-600 underline"
            data-testid="resendButton"
            onClick={onResend}
            type="button"
          >
            Resend code
          </button>
        </div>
        {verifyCodeResent && (
          <div className="mt-2 flex justify-center">
            <Icon asset={CheckIcon} className="mr-1" label="success" size="sm" />
            <p className="text-legacy-green">We sent you a new code</p>
          </div>
        )}
        <Controller
          control={control}
          name="isSMSChecked"
          render={({ field: { onChange } }) => (
            <TermsCheckbox
              centered={false}
              disabled={isSubmitting}
              termsCopy={
                <>
                  I agree to receive Dave’s fraud alerts, special offers, and promotions via SMS.
                  Consent is not a condition to purchase. Message & data rates may apply. Message
                  frequency varies. Reply “STOP” to unsubscribe. View our{' '}
                  <Link to={URLS.TERMS_OF_SERVICE}>
                    <span className="font-bold text-dave-green underline">Terms of Use</span>
                  </Link>{' '}
                  or{' '}
                  <Link to={URLS.PRIVACY_POLICY}>
                    <span className="font-bold text-dave-green underline">Privacy Policy</span>
                  </Link>{' '}
                  for details.
                </>
              }
              onChange={e => {
                onChange(e);
                onCheckboxToggle();
              }}
            />
          )}
        />
        <Button
          data-testid="verifyButton"
          type="submit"
          className="mt-8 w-full"
          color="green"
          disabled={!isValid || !!errors.code || isSubmitting}
          size="standard"
          text="Join Dave"
          variant="primary"
        />
      </form>
    </div>
  );
  /* eslint-enable react/jsx-props-no-spreading */
};

export default VerifyPhone;
