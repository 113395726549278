import React, { FC } from 'react';

type ErrorMessageProps = {
  message: string | undefined;
  inputName?: string;
};

const ErrorMessage: FC<ErrorMessageProps> = ({ message, inputName = 'error' }) => {
  return message ? (
    <p
      aria-live="assertive"
      className="error-message mt-2 text-sm leading-snug text-legacy-error-red"
      id={`${inputName}-message`}
      role="alert"
    >
      {message}
    </p>
  ) : null;
};

export default ErrorMessage;
